<template>
  <div id="nobel-laureates" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
    <div class="page-title-area nobel-bg bg-3">
      <div class="container">
        <div class="page-title-content">
          <h2>Join with 14 Nobel Peace Laureates <br> in the most important work of our time</h2>
        </div>
      </div>
    </div>

    <section class="testimonials-sections ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="section-title">
          <p>These leaders for peace have shaped history across the globe, from ending wars and conflicts, to banning weapons, to securing rights for indigenous peoples, fighting child labor, securing economic equity, defending free speech, and building coalitions. These are the role models that our youth and the world need now.</p>
        </div>

        <div class="loading" v-if="nobelList_data === undefined && !nobelList_error">
          <Loading />
        </div>

        <div class="row" v-if="nobelList_data">
          <div class="col-lg-6 mb-4" v-for="nobelList in nobelList_data.data" :key="nobelList.id">
            <div class="nobel-cards">
              <div class="content">
                <div class="image" :style="{ backgroundImage: 'url(' + ( nobelList.profile_image ? nobelList.profile_image.data.full_url : '' ) + ')' }">
                </div>

                <div class="text">
                  <h3>{{nobelList.name}}</h3>
                  <span>{{nobelList.title}}</span>
                  <p v-if="nobelList.description">{{nobelList.description}}</p>
                  <router-link :to="{ name: 'nobel', params: params({url: nobelList.slug}) }" class="donate-btn d-block mt-3">
                    Read More
                    <i class="flaticon-curve-arrow-1"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import api from "../services/api";
  import Loading from "../components/Loading";
  import useSWRV from "swrv";

  export default {
    name: "NobelLaureates",
    components: {Loading},
    setup() {
      let {
        data: nobelList_data,
        error: nobelList_error,
        isValidating: nobelList_isValidating
      } = useSWRV(() => `items/nobel_list?status=published&fields=id,description,name,title,slug,profile_image.*`, api.fetcher);

      return {
        nobelList_data,
        nobelList_error,
        nobelList_isValidating,
      }
    },
    async mounted() {
      window.scrollTo(0, 0);
    },
    methods: {
      trimMyString(string, maxLength, start = 0) {
        if (string.length > maxLength) {
          let trimmedString = string.substr(start, maxLength)
          return (
              trimmedString.substr(
                  start,
                  Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
              ) + ' ...'
          )
        }
        return string
      },
      params(newParams) {
        return {
          ...this.$route.params,
          ...newParams
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  .donate-btn {
    font-size: 15px;
    font-weight: 600;
    color: #FC4040;
    text-transform: uppercase;
    transition: 0.5s;

    &:hover {
      letter-spacing: 2px;
    }
  }
  .loading {
    height: 500px;
  }
  .section-title {
    p {
      max-width: 100%;
    }
    span::before {
      display: none;
      margin-left: 0;
    }
  }
  .nobel-bg {
    background-image: url("../assets/img/hero-award-bg.jpg");
    background-position: center;

    >div {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
    }
  }
  #nobel-laureates {
    .nobel-cards {
      background-color: #31bfd5;
      height: 100%;

      h3, p {
        color: #fff;
      }

      span {
        color: #373438;
      }

      .content {
        display: flex;
        height: 100%;

        .image {
          width: 40%;
          min-height: 220px;
          background-size: cover;
          background-color: #ededed;
          background-position: center;
        }

        .text {
          width: 60%;
          padding: 25px 20px;

          h3 {
            font-size: 22px;
          }
        }
      }
    }
  }
  @media (max-width: 550px) {
    #nobel-laureates .nobel-cards {
      .content {
        flex-direction: column;

        .image, .text {
          width: 100%;
        }

        .image {
          height: 300px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #363337;
        }
      }
    }
  }
</style>
